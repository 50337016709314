import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MainTitle from 'components/common/admin/MainTitle';
import { getLicense } from 'store/license';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import LicenseUserList from 'components/branch/license/LicenseUserList';
import LicenseAddEdit from 'components/branch/license/LicenseAddEdit';
import '../mudmanage/Conf.scss';
import 'components/common/config/ConfigTemplate.scss';
import './License.scss';

// import alertImg from 'img/setting/alert.png';
// import checkImg from 'img/setting/check.png';
// import forbiddenImg from 'img/setting/forbidden.png';
// import waitingImg from 'img/setting/waiting.png';
// import registerImg from 'img/setting/license-register.png';
// import check2Img from 'img/setting/license-check.png';
// import warningImg from 'img/setting/license-warning.png';

export default function License(props: { [key: string]: any }) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const licenseInfo = useSelector((state: any) => state.license.data, shallowEqual);
  const targetAllCount = useSelector((state: any) => state.license.targetAllCount);
  const needReload = useSelector((state: any) => state.license.needReload);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    dispatch(getLicense());
  }, [needReload]);

  // 라이선스 총 개수
  let totalLicenseCount = 0;
  // 사용 가능한 기간 교육 라이선스 총 수량
  let totalEduPeriodLicenseCnt = 0;
  // 사용 가능한 기간횟수 교육 라이선스 총 수량
  let totalEduPeriodTimesLicenseCnt = 0;
  // 사용 가능한 기간 라이선스 총 수량
  let totalPeriodLicenseCnt = 0;
  // 사용 가능한 횟수 라이선스 총 수량=기간횟수 라이선스에서 동일한 변수 사용
  let totalTimesLicenseCnt = 0;
  // 총 대상자와 라이선스 수량 비교
  let totalLicenseCnt = 0;
  const nowEpochTime = nowEpoch();
  const licenseDataByNo: { [key: string]: any } = {};
  
  const LicenseString: any = { 
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration: 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    EduDurationTimes: 
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) 
      + formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' }),
    TypeEducation: formatMessage({ id: 'License_60', defaultMessage: '[교육]' }),
    TypeTranning:formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) ,
  };

  if(Option.isJapan === 1){
    LicenseString.Duration = (
        <div>
          {formatMessage({ id: 'License_61', defaultMessage: '[훈련]' })}<br/>
          {formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' })}
        </div>
      );
    LicenseString.Times = (
      <div>
        {formatMessage({ id: 'License_61', defaultMessage: '[훈련]' })}<br/>
        {formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스(무제한)' })}
      </div>
    );
    LicenseString.DurationTimes = (
      <div>
        {formatMessage({ id: 'License_61', defaultMessage: '[훈련]' })}<br/>
        {formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' })}
      </div>
    );
    LicenseString.EduDuration = (
      <div>
        {formatMessage({ id: 'License_60', defaultMessage: '[교육]' })}<br/>
        {formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' })}
      </div>
    );
    LicenseString.EduDurationTimes = (
      <div>
        {formatMessage({ id: 'License_60', defaultMessage: '[교육]' })}<br/>
        {formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' })}
      </div>
    );
  }
  // 발급된 라이선스 별 정보
  const existLicenseData: { [key: string]: any } = {
    // 횟수 라이선스
    times: {
      byNo: {},
      total: [],
      // 신청중
      notIssued: [],
      // 모든 횟수 사용
      expired: [],
      // 사용 가능
      using: [],
    },
    // 기간 라이선스
    period: {
      byNo: {},
      total: [],
      // 신청중
      notIssued: [],
      // 만료
      expired: [],
      // 사용중
      using: [],
      // 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
      expected: [],
    },
    // 기간 횟수 라이선스
    periodtimes: {
      byNo: {},
      total: [],
      // 신청중
      notIssued: [],
      // 만료
      expired: [],
      // 사용중
      using: [],
      // 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
      expected: [],
    },
    // 기간 교육 라이선스
    eduPeriod: {
      byNo: {},
      total: [],
      // 신청중
      notIssued: [],
      // 만료
      expired: [],
      // 사용중
      using: [],
      // 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
      expected: [],
    },
    // 기간 횟수 교육 라이선스
    eduPeriodtimes: {
      byNo: {},
      total: [],
      // 신청중
      notIssued: [],
      // 만료
      expired: [],
      // 사용중
      using: [],
      // 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
      expected: [],
    },
  };
  const licenseData = licenseInfo.success;

  for (let i = 0, l = licenseData.length; i < l; i += 1) {
    const licenseInfo = licenseData[i];
    const {
      serviceNo,
      licenseNo,
      serviceName,
      startEpoch,
      endEpoch,
      licenseCount,
      licenseType,
      examUsedCount,
      examLimitCount,
      examLimitInfo,
      regEpoch,
      issueEpoch,
      targetCount,
      approve,
      status,
      licenseEnabled,
    } = licenseInfo;

    // 라이선스 존재
    if (licenseNo) {
      licenseDataByNo[licenseNo] = licenseInfo;
      totalLicenseCount += 1;

      // 발급 받은 라이선스 (승인)
      if (issueEpoch) {
        // 기간 횟수 교육 라이선스
        if (licenseType === 32) {
          existLicenseData.eduPeriodtimes.total.push(licenseNo);
          // 기간 만료
          if (nowEpochTime > endEpoch || status === 3) {
            existLicenseData.eduPeriodtimes.byNo[licenseNo] = 3;
            existLicenseData.eduPeriodtimes.expired.push(licenseNo);
          }
          // 사용 대기 - 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
          else if (nowEpochTime < startEpoch) {
            existLicenseData.eduPeriodtimes.byNo[licenseNo] = 2;
            existLicenseData.eduPeriodtimes.expected.push(licenseNo);
            totalEduPeriodTimesLicenseCnt = licenseCount;
          }
          // 횟수 만료
          else if (examUsedCount === examLimitCount) {
            existLicenseData.eduPeriodtimes.byNo[licenseNo] = 3;
            existLicenseData.eduPeriodtimes.expired.push(licenseNo);
          } else {
            existLicenseData.eduPeriodtimes.byNo[licenseNo] = 1;
            existLicenseData.eduPeriodtimes.using.push(licenseNo);

            if (totalEduPeriodTimesLicenseCnt < licenseCount) {
              totalEduPeriodTimesLicenseCnt = licenseCount;
            }
          }
        }
        // 기간 교육 라이선스
        else if (licenseType === 16) {
          existLicenseData.eduPeriod.total.push(licenseNo);

          // 만료
          if (nowEpochTime > endEpoch || status === 3) {
            existLicenseData.eduPeriod.byNo[licenseNo] = 3;
            existLicenseData.eduPeriod.expired.push(licenseNo);

            // 사용중 - 훈련 가능
          } else if (nowEpochTime >= startEpoch && nowEpochTime <= endEpoch) {
            existLicenseData.eduPeriod.byNo[licenseNo] = 1;
            existLicenseData.eduPeriod.using.push(licenseNo);
            totalEduPeriodLicenseCnt += licenseCount;

            // 사용 대기 - 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
          } else if (nowEpochTime < startEpoch) {
            existLicenseData.eduPeriod.byNo[licenseNo] = 2;
            existLicenseData.eduPeriod.expected.push(licenseNo);
            totalEduPeriodLicenseCnt += licenseCount;
          } else {
            //
            console.log('기간 교육 라이선스 버그');
          }
        }
        // 기간 횟수 라이선스
        else if (licenseType === 8) {
          existLicenseData.periodtimes.total.push(licenseNo);
          // 기간 만료
          if (nowEpochTime > endEpoch || status === 3) {
            existLicenseData.periodtimes.byNo[licenseNo] = 3;
            existLicenseData.periodtimes.expired.push(licenseNo);
          }
          // 사용 대기 - 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
          else if (nowEpochTime < startEpoch) {
            existLicenseData.periodtimes.byNo[licenseNo] = 2;
            existLicenseData.periodtimes.expected.push(licenseNo);
            totalTimesLicenseCnt = licenseCount;
          }
          // 횟수 만료
          else if (examUsedCount === examLimitCount) {
            existLicenseData.periodtimes.byNo[licenseNo] = 3;
            existLicenseData.periodtimes.expired.push(licenseNo);
          } else {
            existLicenseData.periodtimes.byNo[licenseNo] = 1;
            existLicenseData.periodtimes.using.push(licenseNo);

            if (totalTimesLicenseCnt < licenseCount) {
              totalTimesLicenseCnt = licenseCount;
            }
          }
        }
        // 횟수 라이선스
        else if (licenseType === 4) {
          existLicenseData.times.total.push(licenseNo);
          // 만료
          if (examUsedCount === examLimitCount) {
            existLicenseData.times.byNo[licenseNo] = 3;
            existLicenseData.times.expired.push(licenseNo);

            // ----------- 관리자가 이미 사용한 횟수보다 수량 적게 변경했을때 리스트 뜨도록 처리 -----------
            //   // 사용중
            // } else if (examUsedCount < examLimitCount) {
          } else {
            existLicenseData.times.byNo[licenseNo] = 1;
            existLicenseData.times.using.push(licenseNo);

            if (totalTimesLicenseCnt < licenseCount) {
              totalTimesLicenseCnt = licenseCount;
            }
          }

          // ----------- 관리자가 이미 사용한 횟수보다 수량 적게 변경했을때 리스트 뜨도록 처리 -----------
          //   // 버그
          // } else {
          //   console.log('횟수 라이선스 버그');
          // }

          // 기간 라이선스 (+ 영구 라이선스)
        } else if (licenseType === 1 || licenseType === 2) {
          existLicenseData.period.total.push(licenseNo);

          // 만료
          if (nowEpochTime > endEpoch || status === 3) {
            existLicenseData.period.byNo[licenseNo] = 3;
            existLicenseData.period.expired.push(licenseNo);

            // 사용중 - 훈련 가능
          } else if (nowEpochTime >= startEpoch && nowEpochTime <= endEpoch) {
            existLicenseData.period.byNo[licenseNo] = 1;
            existLicenseData.period.using.push(licenseNo);
            totalPeriodLicenseCnt += licenseCount;

            // 사용 대기 - 정상이나 아직 사용기간이 안됨 (앞으로 사용할 라이선스)
          } else if (nowEpochTime < startEpoch) {
            existLicenseData.period.byNo[licenseNo] = 2;
            existLicenseData.period.expected.push(licenseNo);
            totalPeriodLicenseCnt += licenseCount;
          } else {
            //
            console.log('기간 라이선스 버그');
          }
        } else {
          // 버그
          console.log('기간 라이선스 버그');
        }

        // 발급 안된 라이선스 (신청중)
      } else if (!issueEpoch) {
        
        if (licenseType === 32) {
          // 기간+횟수 교육 라이선스
          existLicenseData.eduPeriodtimes.byNo[licenseNo] = 0;
          existLicenseData.eduPeriodtimes.total.push(licenseNo);
          existLicenseData.eduPeriodtimes.notIssued.push(licenseNo);
        }
        else if (licenseType === 16) {
          // 기간 교육 라이선스
          existLicenseData.eduPeriod.byNo[licenseNo] = 0;
          existLicenseData.eduPeriod.total.push(licenseNo);
          existLicenseData.eduPeriod.notIssued.push(licenseNo);
        }
        else if (licenseType === 8) {
          // 기간 횟수 라이선스
          existLicenseData.periodtimes.byNo[licenseNo] = 0;
          existLicenseData.periodtimes.total.push(licenseNo);
          existLicenseData.periodtimes.notIssued.push(licenseNo);
        } else if (licenseType === 4) {
          // 횟수 라이선스
          existLicenseData.times.byNo[licenseNo] = 0;
          existLicenseData.times.total.push(licenseNo);
          existLicenseData.times.notIssued.push(licenseNo);

          // 기간 라이선스 (+ 영구 라이선스)
        } else if (licenseType === 1 || licenseType === 2) {
          existLicenseData.period.byNo[licenseNo] = 0;
          existLicenseData.period.total.push(licenseNo);
          existLicenseData.period.notIssued.push(licenseNo);
        }
      }
    }
  }

  // --------------------------------- rendering 시작 -------------------------------------
  const licenseSummaryDetail = [];
  const licenseSummaryData = {
    color: '',
    icon: '',
    text: '',
    sub: '',
  };

  // 기간 라이선스 정보 및 상태--------------------------------------------------------
  const periodStatusInfo: Array<JSX.Element> = [];
  const periodNotIssuedCnt = existLicenseData.period.notIssued.length;
  const periodUsingCnt = existLicenseData.period.using.length;
  const periodExpiredCnt = existLicenseData.period.expired.length;
  const periodExpectedCnt = existLicenseData.period.expected.length;
  // 신청중
  if (periodNotIssuedCnt > 0) {
    existLicenseData.period.notIssued.map((licenseNo: number, index: number) => {
      periodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-request">
            {index === 0 ? (
              <span>
                <img src="/img/setting/alert.png" alt="img" />
                {formatMessage({ id: 'Status_3', defaultMessage: '신청중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용중
  if (periodUsingCnt > 0) {
    existLicenseData.period.using.map((licenseNo: number, index: number) => {
      periodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-use">
            {index === 0 ? (
              <span>
                <img src="/img/setting/check.png" alt="img" />
                {formatMessage({ id: 'Status_4', defaultMessage: '사용중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 만료
  if (periodExpiredCnt > 0) {
    existLicenseData.period.expired.map((licenseNo: number, index: number) => {
      periodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-expired">
            {index === 0 ? (
              <span>
                <img src="/img/setting/forbidden.png" alt="img" />
                {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용대기
  if (periodExpectedCnt > 0) {
    existLicenseData.period.expected.map((licenseNo: number, index: number) => {
      periodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-ready">
            {index === 0 ? (
              <span>
                <img src="/img/setting/waiting.png" alt="img" />
                {formatMessage({ id: 'Status_5', defaultMessage: '사용대기' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }

  // 횟수 라이선스 정보 및 상태--------------------------------------------------------
  const timesStatusInfo: Array<JSX.Element> = [];
  const timesNotIssuedCnt = existLicenseData.times.notIssued.length;
  const timesUsingCnt = existLicenseData.times.using.length;
  const timesExpiredCnt = existLicenseData.times.expired.length;
  // const timesExpectedCnt = existLicenseData.times.expected.length;

  // 신청중
  if (timesNotIssuedCnt > 0) {
    existLicenseData.times.notIssued.map((licenseNo: number, index: number) => {
      timesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-request">
            {index === 0 ? (
              <span>
                <img src="/img/setting/alert.png" alt="img" />
                {formatMessage({ id: 'Status_3', defaultMessage: '신청중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-count license-count-last">
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount}
              {formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용중
  if (timesUsingCnt > 0) {
    existLicenseData.times.using.map((licenseNo: number, index: number) => {
      timesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-use">
            {index === 0 ? (
              <span>
                <img src="/img/setting/check.png" alt="img" />
                {formatMessage({ id: 'Status_4', defaultMessage: '사용중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-count license-count-last">
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount}
              {formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 만료
  if (timesExpiredCnt > 0) {
    existLicenseData.times.expired.map((licenseNo: number, index: number) => {
      timesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-expired">
            {index === 0 ? (
              <span>
                <img src="/img/setting/forbidden.png" alt="img" />
                {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-count license-count-last">
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount +
                formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }

  // 기간+횟수 라이선스 정보 및 상태--------------------------------------------------------
  const periodtimesStatusInfo: Array<JSX.Element> = [];
  const periodtimesNotIssuedCnt = existLicenseData.periodtimes.notIssued.length;
  const periodtimesUsingCnt = existLicenseData.periodtimes.using.length;
  const periodtimesExpiredCnt = existLicenseData.periodtimes.expired.length;
  const periodtimesExpectedCnt = existLicenseData.periodtimes.expected.length;
  // 신청중
  if (periodtimesNotIssuedCnt > 0) {
    existLicenseData.periodtimes.notIssued.map((licenseNo: number, index: number) => {
      periodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-request">
            {index === 0 ? (
              <span>
                <img src="/img/setting/alert.png" alt="img" />
                {formatMessage({ id: 'Status_3', defaultMessage: '신청중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount}
              {formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용중
  if (periodtimesUsingCnt > 0) {
    existLicenseData.periodtimes.using.map((licenseNo: number, index: number) => {
      periodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-use">
            {index === 0 ? (
              <span>
                <img src="/img/setting/check.png" alt="img" />
                {formatMessage({ id: 'Status_4', defaultMessage: '사용중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount}
              {formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 만료
  if (periodtimesExpiredCnt > 0) {
    existLicenseData.periodtimes.expired.map((licenseNo: number, index: number) => {
      periodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-expired">
            {index === 0 ? (
              <span>
                <img src="/img/setting/forbidden.png" alt="img" />
                {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount +
                formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용대기
  if (periodtimesExpectedCnt > 0) {
    existLicenseData.periodtimes.expected.map((licenseNo: number, index: number) => {
      periodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-ready">
            {index === 0 ? (
              <span>
                <img src="/img/setting/waiting.png" alt="img" />
                {formatMessage({ id: 'Status_5', defaultMessage: '사용대기' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount +
                formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }

  // 기간 교육 라이선스 정보 및 상태--------------------------------------------------------
  const eduPeriodStatusInfo: Array<JSX.Element> = [];
  const eduPeriodNotIssuedCnt = existLicenseData.eduPeriod.notIssued.length;
  const eduPeriodUsingCnt = existLicenseData.eduPeriod.using.length;
  const eduPeriodExpiredCnt = existLicenseData.eduPeriod.expired.length;
  const eduPeriodExpectedCnt = existLicenseData.eduPeriod.expected.length;
  // 신청중
  if (eduPeriodNotIssuedCnt > 0) {
    existLicenseData.eduPeriod.notIssued.map((licenseNo: number, index: number) => {
      eduPeriodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-request">
            {index === 0 ? (
              <span>
                <img src="/img/setting/alert.png" alt="img" />
                {formatMessage({ id: 'Status_3', defaultMessage: '신청중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용중
  if (eduPeriodUsingCnt > 0) {
    existLicenseData.eduPeriod.using.map((licenseNo: number, index: number) => {
      eduPeriodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-use">
            {index === 0 ? (
              <span>
                <img src="/img/setting/check.png" alt="img" />
                {formatMessage({ id: 'Status_4', defaultMessage: '사용중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 만료
  if (eduPeriodExpiredCnt > 0) {
    existLicenseData.eduPeriod.expired.map((licenseNo: number, index: number) => {
      eduPeriodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-expired">
            {index === 0 ? (
              <span>
                <img src="/img/setting/forbidden.png" alt="img" />
                {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용대기
  if (eduPeriodExpectedCnt > 0) {
    existLicenseData.eduPeriod.expected.map((licenseNo: number, index: number) => {
      eduPeriodStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-ready">
            {index === 0 ? (
              <span>
                <img src="/img/setting/waiting.png" alt="img" />
                {formatMessage({ id: 'Status_5', defaultMessage: '사용대기' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 기간+횟수 라이선스 정보 및 상태--------------------------------------------------------
  const eduPeriodtimesStatusInfo: Array<JSX.Element> = [];
  const eduPeriodtimesNotIssuedCnt = existLicenseData.eduPeriodtimes.notIssued.length;
  const eduPeriodtimesUsingCnt = existLicenseData.eduPeriodtimes.using.length;
  const eduPeriodtimesExpiredCnt = existLicenseData.eduPeriodtimes.expired.length;
  const eduPeriodtimesExpectedCnt = existLicenseData.eduPeriodtimes.expected.length;
  // 신청중
  if (eduPeriodtimesNotIssuedCnt > 0) {
    existLicenseData.eduPeriodtimes.notIssued.map((licenseNo: number, index: number) => {
      eduPeriodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-request">
            {index === 0 ? (
              <span>
                <img src="/img/setting/alert.png" alt="img" />
                {formatMessage({ id: 'Status_3', defaultMessage: '신청중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount}
              {formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용중
  if (eduPeriodtimesUsingCnt > 0) {
    existLicenseData.eduPeriodtimes.using.map((licenseNo: number, index: number) => {
      eduPeriodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-use">
            {index === 0 ? (
              <span>
                <img src="/img/setting/check.png" alt="img" />
                {formatMessage({ id: 'Status_4', defaultMessage: '사용중' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount}
              {formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 만료
  if (eduPeriodtimesExpiredCnt > 0) {
    existLicenseData.eduPeriodtimes.expired.map((licenseNo: number, index: number) => {
      eduPeriodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-expired">
            {index === 0 ? (
              <span>
                <img src="/img/setting/forbidden.png" alt="img" />
                {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount +
                formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  // 사용대기
  if (eduPeriodtimesExpectedCnt > 0) {
    existLicenseData.eduPeriodtimes.expected.map((licenseNo: number, index: number) => {
      eduPeriodtimesStatusInfo.push(
        <div key={licenseNo} className="license-status-item">
          <div className="license-status-title license-ready">
            {index === 0 ? (
              <span>
                <img src="/img/setting/waiting.png" alt="img" />
                {formatMessage({ id: 'Status_5', defaultMessage: '사용대기' })}
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            <span className="license-number">No.{licenseNo} </span>
            <span className="license-count">
              {licenseDataByNo[licenseNo].targetUsedCount} /
              {licenseDataByNo[licenseNo].licenseCount +
                formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
            </span>
            <span className="license-date">
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].startEpoch, 3)} ~
              {timeFormatFromUTCEpoch(licenseDataByNo[licenseNo].endEpoch, 3)}
              <br />
              {licenseDataByNo[licenseNo].examUsedCount} /
              {licenseDataByNo[licenseNo].examLimitCount +
                formatMessage({ id: 'License_13', defaultMessage: '회' })}
            </span>
          </div>
        </div>,
      );
      return true;
    });
  }
  licenseSummaryDetail.push(
    <div key={existLicenseData.period.byNo}>
      {/* 기간 */}
      {existLicenseData.period.total && existLicenseData.period.total.length > 0 && (
        <div className="target-domain-list">
          <div className="target-domain">
            {LicenseString.Duration}
            <div className="detail">
              {`( ${existLicenseData.period.total.length} ${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })} )`}
            </div>
          </div>
          <div
            className="target-domain-license"
            // color={licenseSummaryData.color === 'red' ? licenseSummaryData.color : null}
          >
            <div className="license-state">
              <div>{periodStatusInfo}</div>
            </div>
          </div>
        </div>
      )}
      {/* 횟수 */}
      {existLicenseData.times.total && existLicenseData.times.total.length > 0 && (
        <div className="target-domain-list">
          <div className="target-domain">
            {LicenseString.Times}
            <div className="detail">
              {`( ${existLicenseData.times.total.length} ${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })} )`}
            </div>
          </div>
          <div className="target-domain-license">
            <div className="license-state">
              <div>{timesStatusInfo}</div>
            </div>
          </div>
        </div>
      )}
      {/* 기간+횟수 */}
      {existLicenseData.periodtimes.total && existLicenseData.periodtimes.total.length > 0 && (
        <div className="target-domain-list">
          <div className="target-domain">
            {LicenseString.DurationTimes}
            <div className="detail">
              {`( ${existLicenseData.periodtimes.total.length} ${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })} )`}
            </div>
          </div>
          <div className="target-domain-license">
            <div className="license-state">
              <div>{periodtimesStatusInfo}</div>
            </div>
          </div>
        </div>
      )}
      {/* 기간 교육 */}
      {existLicenseData.eduPeriod.total && existLicenseData.eduPeriod.total.length > 0 && (
        <div className="target-domain-list">
          <div className="target-domain">
            {LicenseString.EduDuration}
            <div className="detail">
              {`( ${existLicenseData.eduPeriod.total.length} ${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })} )`}
            </div>
          </div>
          <div
            className="target-domain-license"
            // color={licenseSummaryData.color === 'red' ? licenseSummaryData.color : null}
          >
            <div className="license-state">
              <div>{eduPeriodStatusInfo}</div>
            </div>
          </div>
        </div>
      )}
      {/* 기간+횟수 교육 */}
      {existLicenseData.eduPeriodtimes.total && existLicenseData.eduPeriodtimes.total.length > 0 && (
        <div className="target-domain-list">
          <div className="target-domain">
            {LicenseString.EduDurationTimes}
            <div className="detail">
              {`( ${existLicenseData.eduPeriodtimes.total.length} ${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })} )`}
            </div>
          </div>
          <div className="target-domain-license">
            <div className="license-state">
              <div>{eduPeriodtimesStatusInfo}</div>
            </div>
          </div>
        </div>
      )}
    </div>,
  );

  // 라이선스 타이틀 정보
  let licenseSummaryInfo: { [key: string]: any } = {
    icon: 'info',
    header: '',
    sub: '',
  };

  // 총 대상자와 총 라이선스 수량을 비교
  // 기간 & 횟수 모두 존재
  if (existLicenseData.period.total.length > 0 && existLicenseData.times.total.length > 0) {
    // 기간의 사용 가능한 라이선스의 총 수량이 총 대상자 수를 만족
    if (totalPeriodLicenseCnt >= targetAllCount) {
      totalLicenseCnt = totalPeriodLicenseCnt;
      // 기간이 만족하지 못했을 경우 횟수
    } else {
      totalLicenseCnt = totalTimesLicenseCnt;
    }
    // 기간만 존재
  } else if (existLicenseData.period.total.length > 0) {
    totalLicenseCnt = totalPeriodLicenseCnt;
    // 횟수만 존재
  } else {
    totalLicenseCnt = totalTimesLicenseCnt;
  }

  if (totalLicenseCount <= 0) {
    // 라이선스 발급 필요
    licenseSummaryInfo = {
      color: 'error',
      icon: <img src="/img/setting/license-warning.png" alt="img" />,
      header: formatMessage({ id: 'License_45', defaultMessage: '라이선스 발급이 필요합니다.' }),
      sub: formatMessage({
        id: 'License_46',
        defaultMessage: "훈련 진행을 위한 '라이선스'를 구매하세요.",
      }),
    };
  } else if (totalLicenseCnt < targetAllCount) {
    // 발급 라이선스가 등록 대상자보다 적습니다
    licenseSummaryInfo = {
      color: 'error',
      icon: <img src="/img/setting/license-check.png" alt="img" />,
      header: Option.isJapan
        ? formatMessage({
            id: 'License_59',
            defaultMessage: '훈련 실시를 위해서는 라이센스가 필요합니다.',
          })
        : formatMessage({
            id: 'License_47',
            defaultMessage: '발급 라이선스가 등록된 대상자보다 적습니다.',
          }),
      sub: Option.isJapan
        ? ''
        : formatMessage({
            id: 'License_48',
            defaultMessage: '원활한 훈련 진행을 위해서는 라이선스 추가 발급이 필요합니다.',
          }),
    };
  } else if (targetAllCount <= totalLicenseCnt) {
    // 적합한 라이선스
    licenseSummaryInfo = {
      color: 'success',
      icon: <img src="/img/setting/license-check.png" alt="img" />,
      header: formatMessage({
        id: 'License_49',
        defaultMessage: '훈련에 적합한 라이선스가 발급되어 있습니다.',
      }),
      sub: Option.isJapan
        ? ''
        : formatMessage({
        id: 'License_50',
        defaultMessage: '모든 훈련 대상자에 해당하는 라이선스가 발급되었습니다.',
      }),
    };
  }

  const licenseSummary = (
    <div className="license-user-summary-box">
      <div className="license-user-result">
        {licenseSummaryInfo.icon}
        <div className="license-result-content">
          <div className="license-result-header">{licenseSummaryInfo.header}</div>
          {licenseSummaryInfo.sub}
        </div>
      </div>
      {targetAllCount > 0 && totalLicenseCount > 0 && (
        <div className="license-user-detail">
          {licenseSummaryDetail.length > 0
            ? licenseSummaryDetail
            : formatMessage({
                id: 'StartExam_18',
                defaultMessage: '훈련 대상자 없음',
              })}
        </div>
      )}
    </div>
  );

  // ----------------- 라이선스별 정보 -----------------
  // 기간횟수 교육 라이선스
  const eduPeriodtimesLicenseGrid: Array<JSX.Element> = [];
  existLicenseData.eduPeriodtimes.total.map((licenseNo: number) => {
    eduPeriodtimesLicenseGrid.push(
      <LicenseUserList
        key={licenseNo}
        licenseInfo={licenseDataByNo[licenseNo]}
        existLicenseType="eduPeriodtimes"
        existLicenseData={existLicenseData}
      />,
    );
    return true;
  });
  // 기간 교육 라이선스
  const eduPeriodLicenseGrid: Array<JSX.Element> = [];
  existLicenseData.eduPeriod.total.map((licenseNo: number) => {
    eduPeriodLicenseGrid.push(
      <LicenseUserList
        key={licenseNo}
        licenseInfo={licenseDataByNo[licenseNo]}
        existLicenseType="eduPeriod"
        existLicenseData={existLicenseData}
      />,
    );
    return true;
  });
  // 기간횟수 라이선스
  const periodtimesLicenseGrid: Array<JSX.Element> = [];
  existLicenseData.periodtimes.total.map((licenseNo: number) => {
    periodtimesLicenseGrid.push(
      <LicenseUserList
        key={licenseNo}
        licenseInfo={licenseDataByNo[licenseNo]}
        existLicenseType="periodtimes"
        existLicenseData={existLicenseData}
      />,
    );
    return true;
  });
  // 횟수 라이선스
  const timesLicenseGrid: Array<JSX.Element> = [];
  existLicenseData.times.total.map((licenseNo: number) => {
    timesLicenseGrid.push(
      <LicenseUserList
        key={licenseNo}
        licenseInfo={licenseDataByNo[licenseNo]}
        existLicenseType="times"
        existLicenseData={existLicenseData}
      />,
    );
    return true;
  });
  // 기간 라이선스
  const periodLicenseGrid: Array<JSX.Element> = [];
  existLicenseData.period.total.map((licenseNo: number) => {
    periodLicenseGrid.push(
      <LicenseUserList
        key={licenseNo}
        licenseInfo={licenseDataByNo[licenseNo]}
        existLicenseType="period"
        existLicenseData={existLicenseData}
      />,
    );
    return true;
  });

  const licenseGrid = (
    <div>
      {periodLicenseGrid && periodLicenseGrid.length > 0 && (
        <div className="mt-30">
          <div className="bold mb-10">
            {LicenseString.Duration}
          </div>
          <div>{periodLicenseGrid}</div>
        </div>
      )}
      {timesLicenseGrid && timesLicenseGrid.length > 0 && (
        <div className="mt-30">
          <div className="bold mb-10">
            {LicenseString.Times}
          </div>
          <div>{timesLicenseGrid}</div>
        </div>
      )}
      {periodtimesLicenseGrid && periodtimesLicenseGrid.length > 0 && (
        <div className="mt-30">
          <div className="bold mb-10">
            {LicenseString.DurationTimes}
          </div>
          <div>{periodtimesLicenseGrid}</div>
        </div>
      )}
      {eduPeriodLicenseGrid && eduPeriodLicenseGrid.length > 0 && (
        <div className="mt-30">
          <div className="bold mb-10">
            {LicenseString.EduDuration}
          </div>
          <div>{eduPeriodLicenseGrid}</div>
        </div>
      )}
      {eduPeriodtimesLicenseGrid && eduPeriodtimesLicenseGrid.length > 0 && (
        <div className="mt-30">
          <div className="bold mb-10">
            {LicenseString.EduDurationTimes}
          </div>
          <div>{eduPeriodtimesLicenseGrid}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className="mypage-license-area">
      <div className="admin-basic-conf">
        <MainTitle
          className="small"
          title={formatMessage({ id: 'License_52', defaultMessage: '라이선스 관리' })}
        >
          {Option.isSaas === 1 && (Option.isJapan === 0 || isAdmin === 1) && (
            <button type="button" onClick={() => setIsModal(true)}>
              {formatMessage({ id: 'License_30', defaultMessage: '발급 신청' })}
            </button>
          )}
        </MainTitle>
        <div className="config-template">
          <div className="config-class">
            <div className="config-class-box">
              <div className="license-grid-box">
                {licenseSummary}
                {licenseGrid}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <LicenseAddEdit
          visible={isModal}
          isAdmin={0}
          dataInfo={{}}
          isAddMode
          toggleModal={setIsModal}
        />
      )}
    </div>
  );
}
