import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Switch, Popover, Slider } from 'antd';
import moment from 'moment';
import parse from 'html-react-parser';
import * as valid from 'utils/validation';
import { epochFromDate, fitTwoDigit } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import FormTextField from 'components/common/FormTextField';
import FormDatePicker from 'components/common/FormDatePicker';
import FormSwitchField from 'components/common/FormSwitchField';

// import qmarkImage from 'img/exam/startExam/btn_qmark.png';
// import minusImage from 'img/exam/startExam/btn_minus.png';
// import plusImage from 'img/exam/startExam/btn_plus.png';

function StartExamStep2(props: any) {
  const {
    register,
    errors,
    control,
    onSaveForm,
    mode,
    editValues,
    setValue,
    loadData,
    examMode,
    examData,
  } = props;
  const { formatMessage } = useIntl();
  const formStoreData = useSelector((state: any) => state.form.formStore, shallowEqual);
  const [values, setValues] = useState({
    examName:
      formStoreData.examName ||
      (loadData &&
        (examMode === 'expert'
          ? loadData.examName
          : `${loadData?.examName}_${moment().format('YYYYMMDD')}`)) ||
      '',
    startDate:
      formStoreData.startDate ||
      (loadData && loadData?.examStartEpoch !== 0 && moment(loadData?.examStartEpoch * 1000)) ||
      moment().startOf('day'),
    endDate:
      formStoreData.endDate ||
      (loadData && loadData?.examEndEpoch !== 0 && moment(loadData?.examEndEpoch * 1000)) ||
      moment().endOf('day').add(1, 'd'),
    sendReserveEnable: formStoreData.sendReserveEnable || '0',
    sendReserveHour: formStoreData.sendReserveHour || [0, 24],
    sendPeriod: formStoreData.sendPeriod || 1,
    randomSend: formStoreData.randomSend || '0',
  });

  useEffect(() => {
    // 값 변경 시 폼 스토어에 저장
    onSaveForm({ ...values });
  }, [values]);

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    let isDisable = false;
    if (type === 'startDate') {
      isDisable =
        // 현재일(오늘)보다 작을 때
        current < moment().startOf('day');
    } else {
      isDisable =
        // 시작일자보다 작을 때
        current < moment(values.startDate).add(1, 'd') ||
        // 현재일(오늘)보다 작을 때
        current < moment().startOf('day') ||
        // 시작일자로부터 1년이 지날 때(최대 1년 지정 가능)
        current > moment(values.startDate).add(1, 'y');
    }

    return isDisable;
  };

  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any) => {
    let sendPeriod = null;
    let startDate = null;
    let endDate = null;
    if (type === 'startDate') {
      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(values.endDate)) {
        startDate = moment(current).startOf('day');
        endDate = null;
        sendPeriod = 1;
      } else {
        sendPeriod = values.endDate.diff(current, 'd');
        startDate = moment(current).startOf('day');
        endDate = values.endDate;
      }
    } else if (type === 'endDate') {
      sendPeriod = current.diff(values.startDate, 'd');
      startDate = values.startDate;
      endDate = moment(current).hour(23).minute(59).second(59).millisecond(0);
    }
    if (values.sendPeriod > sendPeriod) {
      setValues({
        ...values,
        startDate: startDate,
        endDate: endDate,
        sendPeriod: sendPeriod,
      });
    } else {
      setValues({
        ...values,
        startDate: startDate,
        endDate: endDate,
      });
    }
  };

  // 나눔 발송 기간 설정
  const handlePeriod = (type: string) => {
    if (type === 'plus') {
      if (
        (epochFromDate(values.endDate) - epochFromDate(values.startDate)) / 86400 >
        values.sendPeriod + 1
      ) {
        setValues({ ...values, sendPeriod: values.sendPeriod + 1 });
      }
    } else if (type === 'minus' && values.sendPeriod > 1) {
      setValues({ ...values, sendPeriod: values.sendPeriod - 1 });
    }
  };

  // 훈련 메일 나눔 발송
  let sendPeriodTextComponent = null;
  if (values.sendReserveEnable === '1') {
    const endPeriod = moment(values.startDate).add(values.sendPeriod - 1, 'd');
    const dateFormat =
      (!values.startDate.isSame(endPeriod, 'year') && 'YYYY.M.D') ||
      (!values.startDate.isSame(endPeriod, 'month') && 'M.D') ||
      'D';
    const jpDateFormat = endPeriod.format('YYYY.M.D');
    const endPeriodText =
      values.sendPeriod > 1
        ? `~ ${Option.isJapan ? jpDateFormat : endPeriod.format(dateFormat)},`
        : ',';

    let sendReserveHourText = `${formatMessage({ id: 'StartExam_41', defaultMessage: 'ship ' })}
      ${formatMessage({ id: 'StartExam_39', defaultMessage: 'between ' })}
      ${values.sendReserveHour[0]}
      ${Option.isJapan ? formatMessage({ id: 'Time_1', defaultMessage: '시' }) : ''} 
      ${formatMessage({ id: 'StartExam_38', defaultMessage: ' ~ ' })} 
      ${values.sendReserveHour[1]}
      ${formatMessage({ id: 'Time_1', defaultMessage: '시' })} 
      ${formatMessage({ id: 'StartExam_42', defaultMessage: '사이에 메일 발송' })}`;
    if (values.sendReserveHour[0] === values.sendReserveHour[1]) {
      sendReserveHourText = `${formatMessage({ id: 'StartExam_40', defaultMessage: 'ship at ' })} 
      ${values.sendReserveHour[0]}
      ${formatMessage({ id: 'Time_1', defaultMessage: '시' })} 
      ${formatMessage({ id: 'StartExam_43', defaultMessage: '에 메일 발송' })}`;
    }

    sendPeriodTextComponent = (
      <div className="send-period-text">
        {`※ ${moment(values.startDate).format('YYYY.M.D')} ${endPeriodText} ${formatMessage({
          id: 'Date_18',
          defaultMessage: '일',
        })} ${sendReserveHourText}`}
      </div>
    );
  }

  return (
    <div className="step2-content">
      {/* 훈련명 */}
      <div className="content-item">
        <div className="input-title">
          {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
        </div>
        <FormTextField
          name="examName"
          defaultValue={formStoreData.examName}
          error={errors.examName}
          arrowPosition="top"
          register={register}
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              name: (value: any) => valid.name(value),
              examName: (value: any) =>
                Option.customer === 'saxa' ? valid.saxaExamName(value) : valid.examName(value),
            },
          }}
          onChange={(e: any) => setValues({ ...values, examName: e.target.value })}
        />
      </div>

      {/* 시작일자/종료일자 */}
      <div className="content-item">
        <div className="date-picker-area">
          <div className="date-picker-item">
            <div className="input-title">
              {formatMessage({ id: 'Date_11', defaultMessage: '시작일자' })}
            </div>
            <FormDatePicker
              control={control}
              name="startDate"
              value={values.startDate}
              error={errors.startDate}
              handleOnChange={handleOnChangeDate}
              handleDisableDate={handleDisableDate}
              helperText={
                mode === 'edit'
                  ? ''
                  : moment() < values.startDate &&
                    formatMessage({ id: 'StartExam_36', defaultMessage: '훈련을 예약함' })
              }
              disabled={mode === 'edit' ? values.startDate < moment() : false}
            />
          </div>
          <div className="date-picker-item">
            <div className="input-title">
              {formatMessage({ id: 'Date_12', defaultMessage: '종료일자' })}
            </div>
            <FormDatePicker
              control={control}
              name="endDate"
              value={values.endDate}
              error={errors.endDate}
              validation={{
                validate: { required: (value: any) => valid.required(value || values.endDate) },
              }}
              handleOnChange={handleOnChangeDate}
              handleDisableDate={handleDisableDate}
              helperText={
                mode === 'edit'
                  ? ''
                  : values.endDate &&
                    `${formatMessage({
                      id: 'Period_3',
                      defaultMessage: '훈련 기간',
                    })}: ${moment.duration(values.endDate.diff(values.startDate)).humanize()}`
              }
              arrowPosition="top"
              disabled={
                mode === 'edit'
                  ? values.endDate < moment().startOf('day') || examData?.eduWaitCount > 0
                  : false
              }
            />
          </div>
        </div>
      </div>

      {/* 랜덤발송 여부 */}
      {mode !== 'edit' && !Option.isJapan && (
        <div className="content-item">
          <div className="random-switch">
            {formatMessage({ id: 'StartExam_68', defaultMessage: '랜덤발송' })}
            <FormSwitchField
              className="input-switch"
              control={control}
              name="randomSend"
              defaultValue={formStoreData.randomSend === '1' ? true : false}
              handleOnChange={(name: string, value: number) => {
                setValues({ ...values, randomSend: value ? '1' : '0' });
              }}
            />
          </div>
        </div>
      )}

      {/* 훈련 메일 나눔 발송 */}
      {(mode !== 'edit' || (mode === 'edit' && editValues.sendReserveEnable === '1')) && (
        <div className="content-item">
          <div className="start-exam-border-box">
            <div className="header-box">
              <div className="title bold">
                {formatMessage({ id: 'Send_3', defaultMessage: '훈련 메일 나눔 발송' })}
                <Popover
                  placement="right"
                  content={parse(
                    formatMessage({
                      id: 'StartExam_44',
                      defaultMessage:
                        "<div>훈련 메일을 아래에서 설정한</div><div><b>'발송 기간'</b>과 <b>'발송 시간'</b> 사이에</div><div>발송합니다.</div>",
                    }),
                  )}
                >
                  <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
                </Popover>
              </div>
              <Controller
                control={control}
                name="sendReserveEnable"
                render={({ onChange }) => (
                  <Switch
                    onChange={(value: any) => {
                      onChange(value ? '1' : '0');
                      setValues({ ...values, sendReserveEnable: value ? '1' : '0' });
                    }}
                    checked={values.sendReserveEnable === '1' ? true : false}
                  />
                )}
              />
            </div>
            <div className={`setting-box${values.sendReserveEnable === '0' ? ' disabled' : ''}`}>
              <div className="setting-row">
                <div className="input-title">
                  {formatMessage({ id: 'Date_22', defaultMessage: '나눔 발송 기간(일)' })}
                </div>
                <div className="send-value">
                  <div className="period-area">
                    <img
                      src="/img/exam/startExam/btn_minus.png"
                      alt="minus"
                      onClick={() => handlePeriod('minus')}
                      aria-hidden="true"
                    />
                    <div className="period-text">{values.sendPeriod}</div>
                    {(epochFromDate(values.endDate) - epochFromDate(values.startDate)) / 86400 >
                    values.sendPeriod + 1 ? (
                      <img
                        src="/img/exam/startExam/btn_plus.png"
                        alt="plus"
                        onClick={() => handlePeriod('plus')}
                        aria-hidden="true"
                      />
                    ) : (
                      <Popover
                        placement="top"
                        content={formatMessage({
                          id: 'StartExam_37',
                          defaultMessage: '훈련 기간보다 1일 적게 입력하세요.',
                        })}
                      >
                        <img src="/img/exam/startExam/btn_plus.png" alt="plus" />
                      </Popover>
                    )}
                  </div>
                </div>
              </div>
              <div className="setting-row">
                <div className="input-title">
                  {formatMessage({ id: 'Time_8', defaultMessage: '나눔 발송 시간' })}
                </div>
                <div className="send-value">
                  <div className="slider-area">
                    <Controller
                      control={control}
                      name="sendReserveHour"
                      render={({ onChange }) => (
                        <Slider
                          range
                          // defaultValue={[0, 24]}
                          value={values.sendReserveHour}
                          min={0}
                          max={24}
                          tooltipVisible={false}
                          onChange={(value: any) => {
                            onChange(value);
                            setValues({ ...values, sendReserveHour: value });
                          }}
                          disabled={values.sendReserveEnable === '0'}
                        />
                      )}
                    />
                  </div>
                  <div className="flex">
                    <div className="slider-status">{`${values.sendReserveHour[0]} ${formatMessage({
                      id: 'Time_1',
                      defaultMessage: '시',
                    })}`}</div>
                    <div className="slider-status">{`${values.sendReserveHour[1]} ${formatMessage({
                      id: 'Time_1',
                      defaultMessage: '시',
                    })}`}</div>
                  </div>
                </div>
              </div>

              {/* 훈련 메일 나눔 발송 안내 */}
              {sendPeriodTextComponent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StartExamStep2;
